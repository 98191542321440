<template>
  <div class="search-card">
    <div
      v-if="place === 'center'"
      class="center-card"
    >
      <div
        v-for="(item, centerIndex) in list"
        :key="centerIndex"
        :class="item.value === select ? 'blue-class' : ''"
        @click="changeType(item)"
      >
        {{ item.label }}
        <span v-show="item.value === select" />
      </div>
      <slot />
    </div>

    <div
      v-else
      class="header-card"
    >
      <div
        v-for="(item, headerIndex) in list"
        :key="headerIndex"
        :class="select === item.value ? 'is-select': ''"
        @click="changeType(item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    select: {
      type: String,
      default: ''
    },
    place: {
      type: String,
      default: 'header'
    }
  },
  mounted() {
    console.log(this.list, 'list')
  },
  methods: {
    changeType(item) {
      if (this.select === item.value) {
        return null
      }
      this.$emit('change', item)
    }
  }
}
</script>

<style scoped lang="scss">
  .search-card {
    .center-card {
      div {
        display: inline-block;
        min-width:60px;
        text-align: center;
        vertical-align: center;
        padding: 0 12px;
        font-size:14px;
        color:#99A9BF;
        height: 45px;
        line-height: 45px;
        cursor:pointer;
        position: relative;
        text-align: center;
        span {
          position: absolute;
          display: block;
          height: 2px;
          width:19px;
          background: #20A0FF;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .blue-class {
        color:#20A0FF;
      }
    }
    .header-card {
      display: flex;
      align-items: center;
      overflow: hidden;
      div {
        width: 96px;
        height: 38px;
        line-height: 36px;
        font-size: 12px;
        color: #666;
        text-align: center;
        cursor: pointer;
        border: 1px solid #D9DDE1;
        border-bottom: none;
        border-left: 0;
      }
      div:nth-of-type(1) {
        border-left: 1px solid #D9DDE1;
      }
      .is-select {
        background: #20A0FF;
        color: #fff;
        border-color: #20A0FF !important;
      }
    }
  }
</style>
